var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"HeaderSearch",class:[
    {'HeaderSearch--open': _vm.$store.state.search.headerSearchInputOpen},
    _vm.$themeSettings.components.HeaderSearch.breakpoint.hide
      ? `d-${_vm.$themeSettings.components.HeaderSearch.breakpoint.hide}-none`
      : '',
    _vm.$themeSettings.components.HeaderSearch.breakpoint.show
      ? `d-${_vm.$themeSettings.components.HeaderSearch.breakpoint.show}-flex`
      : '',
  ]},[_c('div',{staticClass:"HeaderSearch__inputWrapper"},[(_vm.$themeSettings.components.HeaderSearch.contactInfo)?_c('div',{staticClass:"HeaderSearch__contact-info"},[_c('span',[_c('svgicon',{staticClass:"svg-icon-offset-right",attrs:{"icon":require('@icon/phone.svg?raw'),"fill":""}}),_c('a',{staticClass:"HeaderSearch__phone mr-2 text-body",attrs:{"href":`tel:${_vm.phoneHref}`,"aria-label":_vm.accessiblePhoneNumber}},[_c('strong',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm.contact.phone))])]),_c('span',{staticClass:"HeaderSearch__openingHours"},[_vm._v(" "+_vm._s(_vm.contact.openingHours)+" ")])],1)]):_vm._e(),_c('SearchInput',{attrs:{"input-id":'HeaderSearchInput',"autofocus":_vm.$store.state.search.headerSearchInputOpen}})],1),(_vm.$themeSettings.components.HeaderSearch.close.enable)?_c('div',{staticClass:"HeaderSearch__close",on:{"click":_vm.closeSearch}},[_c('svgicon',{attrs:{"icon":require('@icon/cross.svg?raw')}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }